import { Component, OnInit, Inject } from "@angular/core";
import { ModalBasicoComponent } from "../modal-basico/modal-basico.component";
import { User } from "src/app/models/user";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { InstituicaoService } from "src/app/services/instituicao.service";
import { TipoInscricaoService } from "src/app/services/tipo-inscricao.service";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { EventEmitter } from "@angular/core";
import util from "../../../../app/util";
import { EventoService } from "src/app/services/evento.service";

@Component({
  selector: 'app-modal-desconto',
  templateUrl: './modal-desconto.component.html',
  styleUrls: ['./modal-desconto.component.scss']
})
export class ModalDescontoComponent implements OnInit {
  form = {
    porcentagem:null,
    usuarioId:0,
    eventoId:0,
    AdminId:0,
    observacoes:''
  }
  porcentagem = new FormControl("", [Validators.required,Validators.max(100)]);
  observacoes = new FormControl("");
  onAdd = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventoService:EventoService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form.AdminId = this.data.adminId;
    this.form.eventoId = this.data.eventoId;
    this.form.usuarioId = this.data.id;
  }
  salvar(){
    console.log(this.form)
    if (this.porcentagem.valid) {
      this.eventoService.desconto(this.form).then((r)=>{
        this.onNoClick()
      }).catch((err)=>{
        console.log(err)
      })
    }
  }
  onNoClick(): void {
    this.onAdd.emit();
    this.dialogRef.close();
  }
  cancelar() {
    this.dialogRef.close();
  }

}
